import { extendTheme } from '@chakra-ui/theme-utils';

const theme = extendTheme({
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
      },
    },
  },
});

export default theme;
